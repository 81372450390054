<template>
    <div class="about">
        <section id="cms-content" class="cms-despre-noi">
            <div class="container">
                <div class="cms-content">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="section-title">{{ $t("about-us.title") }}</h1>
                            <!--<h1 class="section-title" v-html="$t('about-us.title')"></h1> // punem traducerea in format html-->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">

                            <img class="img-responsive" src="../assets/img/cms/partner_banner-1.jpg"
                                 alt="Devino partener Koff.ro">

                            <h2 class="title">{{ $t("about-us.subtitles.s1") }}</h2>
                            <p>{{ $t("about-us.content.para-1") }}</p>
                            <p>{{ $t("about-us.content.para-2") }}</p>
                            <p>{{ $t("about-us.content.para-3") }}</p>

                            <h2 class="title">{{ $t("about-us.subtitles.s2") }}</h2>
                            <ul class="checked-list">
                                <li>
                                    {{ $t("about-us.content.para-4") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-5") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-6") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-7") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-8") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-9") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-10") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-11") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-12") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-13") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-14") }}
                                </li>
                                <li>
                                    {{ $t("about-us.content.para-15") }}
                                </li>
                            </ul>

                            <h2 class="title">{{ $t("about-us.subtitles.s3") }}</h2>
                            <p>{{ $t("about-us.content.para-16") }}</p>
                            <a href="https://shop.koff.ro/register" target=”_blank” class="btn-blue">{{ $t("about-us.register-button") }}</a>

                            <h2 class="title">{{ $t("about-us.subtitles.s4") }}</h2>
                            <p>{{ $t("about-us.content.para-17") }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="becomepartner">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 d-flex">
                        <div id="partner-text">
                            <h3>{{ $t('home.partner.title') }}</h3>
                            <p>{{ $t('home.partner.content') }}</p>
                            <a href="https://shop.koff.ro/register" target=”_blank” class="btn btn-blue ripple">{{ $t('home.partner.register-button') }}</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div id="partner-img">
                            <img src="../assets/img/img-partners.jpg" class="img-responsive" alt="parteneri koff">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
</script>

<style lang="scss">
    @import '../assets/scss/cms.scss';

    .checked-list li {
        position: relative;
        padding-left: 30px;
    }

    .checked-list li:before {
        content: url("../assets/img/icons/check2.png");
        position: absolute;
        top: 2px;
        left: 0;
    }

</style>
